.tableContainer{
    margin: 0 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Jura;
}
.button {
    align-items: center;
    background-color: transparent;
    color: rgb(189, 19, 19);
    cursor: pointer;
    display: flex;
    font-family: "Jura";
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    text-decoration:underline;
    outline: 0;
    border: 0;
    padding: 1rem;
  }
  
.button:before {
    background-color:black;
    content: "";
    display: inline-block;
    height: 1px;
    margin-right: 10px;
    transition: all .42s cubic-bezier(.25,.8,.25,1);
    width: 0;
  }
  
.button:hover:before {
    background-color: black;
    width: 1.5rem;
  }

.table{
    border-collapse: collapse;
    width: 90%;
    border: 2px solid rgb(22, 19, 19) ;
    text-align: center;
}

.celda{
    border: 1px solid black;
    padding: 5px;
}


.download {
  font-family: monospace;
  font-size: 1rem;
  color: #FAFAFA;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 10px;
  border: 2px solid #FAFAFA;
  background: #b6b3b3;
  box-shadow: 3px 3px #fafafa;
  cursor: pointer;
}

.download:active {
  box-shadow: none;
  transform: translate(3px, 3px);
}