.flyer {
    background-image: url("../../Assets/Images/fondoAbout.jpg");
    background-size: cover;
    background-position: center;
    padding-top: 7%;
    padding-bottom: 7%;
    position: relative; /* Para que el color de fondo absoluto se posicione correctamente */
}

.flyer::before {
    content: ""; /* Se utiliza para agregar contenido generado */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(179, 47, 18,0.4); /* Color rojo translúcido */
    mix-blend-mode: multiply; /* Mezcla de color */
}

.flyer p {
    display: flex;
    justify-content: center;
    font-family: Jura;
    font-size: 45px;
    color:rgb(255, 255, 255) ;
}

.welcome{
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 12% 0 12%;
}
.welcome p {
    font-family: Jura;
    font-size: 18px;
}

.mision{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: 0 12% 0 12%;
}

.Title{
    margin: 0;
    font-family: Jura;
    font-weight: bolder;
    font-size: 30px;
    color:#B32F12
}

.misionText{
    font-family: Jura;
    font-size: 18px;
}

.titleContainer{
    display: flex;
    justify-content: center;
}
.Valores{
    margin: 0 12% 5% 12%;
}

.subtitle{
    font-family: Jura;
    font-size: 22px;
    font-weight: 700;
    color:#0F0F0F
}

.valoresText{
    font-family: Jura;
}


@media (max-width: 650px) {
  
    .welcome p {
        font-size: 14px;
    }
    .misionText{
        font-size: 14px;
    }
    .subtitle{
        font-size: 19px;
    }
    .valoresText{
        font-size: 14px;
    }
    
      }

      @media (max-width: 590px) {
        .flyer p {
            font-size: 35px;
        }
      }

      @media (max-width: 450px) {
        .flyer p {
            font-size: 30px;
        }
      }