.Img{
    width: 180px;
    height: 180px;
}

.imgContainer{
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.Card{
    width: 60%;
    display: flex;
    border-radius: 5px;
    padding: 1% 5% 1% 5%;
    margin-top: 25px;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.3);
}

.imgContainer{
    display: flex;
    align-items: center;
    margin-right: 10px;
    flex-shrink: 0; /* Evitar que el contenedor de imagen se reduzca */
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.cardFooter{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
}

@font-face {
    font-family: 'Jura';
    src: url('../../Assets/Fonts/Jura-VariableFont_wght.ttf') format('truetype');
  }


.title{
    font-size: 25px;
    text-align: center;
    margin: 0 0 10px 0;
    font-family: Jura;
    text-transform: uppercase;
}

.description{
    font-family: Jura;
    font-size: 16px;
    width: 100%;
}

.cardFooter{
    display: flex;
    justify-content:space-around;
}

.button {
    padding: 12.5px 25px;
    border: 0;
    border-radius: 100px;
    background-color: #b33b2b;
    color: #ffffff;
    font-weight: Bold;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    font-size: 12px;
  }

  .button:hover {
    background-color: #c24f40;
    transform: scale(1.1);
  }
  
  .button:active {
    background-color: #cf3d3d;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    box-shadow: none;
    transform: scale(0.98);
  }

.verMas{
    color: red;
    cursor: pointer;
}
  

@media (max-width: 690px) {
    .title{
      font-size: 20px;
    }
  }


  @media (max-width: 650px) {
  
.description{
    font-size: 14px;
}
  }


  @media (max-width: 600px) {
  
    .Img{
        width: 160px;
        height: 160px;
    }
      }

      @media (max-width: 550px) {
        .cardFooter p{
            display: none;
        }

        .title{
            font-size: 18px;
          }

          .description{
            font-size: 12px;
        }
          }


          @media (max-width: 500px) {
            .Card{
                align-items: center;
                flex-direction: column;
            }
            .title{
                order: 1;
            }
            .description{
                margin: 0;
                order: 3;
            }
            .imgContainer{
                order: 0;
            }
            .cardFooter{
                order: 4;
            }
            .Img{
                width: 220px;
                height: 180px;
            }
            .button {
                padding: 10px 20px;
                font-size: 10px;
                margin: 0;
              }
            }
        
    
     