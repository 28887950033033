.formContainer{
    margin: 3% 25% 5% 25%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(235, 232, 230);
    padding-bottom: 2%;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
}

.title{
    font-family: Jura;
    font-weight:900;
    font-size: 30px;
    display: flex;
    justify-content: center;
    margin: 10px 0 10px 0;
}

.input {
    margin: 0 0 1% 0;
    font-family: Jura;
    height: 30px;
    background-color: #04050c0a;
    border-radius: .5rem;
    padding: 0 1rem;
    border: 2px solid ;
    font-size: 1rem;
    transition: border-color .3s cubic-bezier(.25,.01,.25,1) 0s, color .3s cubic-bezier(.25,.01,.25,1) 0s,background .2s cubic-bezier(.25,.01,.25,1) 0s;
    width:30%;
}

.textArea{
    margin: 1% 0 0 0;
    min-width: 60%;
    max-width: 60%;
    min-height: 200px;
    max-height: 200px;
    background-color: #04050c0a;
    border-radius: .5rem;
    padding: 5px 5px 5px 5px;
    border: 2px solid ;
    font-size: 1rem;
    transition: border-color .3s cubic-bezier(.25,.01,.25,1) 0s, color .3s cubic-bezier(.25,.01,.25,1) 0s,background .2s cubic-bezier(.25,.01,.25,1) 0s;
}

.error{
    font-family: Jura;
    color: red;
}

.label{
    font-family: Jura;
    font-weight:900;
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}


.Btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    background-color:#B32F12;

  }
  
  /* plus sign */
  .sign {
    width: 100%;
    font-size: 2em;
    color: white;
    transition-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* text */
  .text {
    position: absolute;
    right: 0%;
    width: 0%;
    opacity: 0;
    color: white;
    font-size: 1.2em;
    font-weight: 500;
    transition-duration: .3s;
  }
  /* hover effect on button width */
  .Btn:hover {
    width: 180px;
    border-radius: 0px;
    transition-duration: .3s;
  }
  
  .Btn:hover .sign {
    width: 50%;
    transition-duration: .3s;
    padding-left: 0px;
  }
  /* hover effect button's text */
  .Btn:hover .text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 20px;
  }
  /* button click effect*/
  .Btn:active {
    transform: translate(2px ,2px);
  }


  .button {
    align-items: center;
    background-color: transparent;
    color: rgb(189, 19, 19);
    cursor: pointer;
    display: flex;
    font-family: "Jura";
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    text-decoration:underline;
    outline: 0;
    border: 0;
    padding: 1rem;
  }
  
.button:before {
    background-color:black;
    content: "";
    display: inline-block;
    height: 1px;
    margin-right: 10px;
    transition: all .42s cubic-bezier(.25,.8,.25,1);
    width: 0;
  }
  
.button:hover:before {
    background-color: black;
    width: 1.5rem;
  }