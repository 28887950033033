.detailContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    font-family: Jura;
    font-size: 25px;
    font-weight: bolder;
    text-transform: uppercase;
}
.img{
    width: 350px;
    height: 350px;
    border-radius: 10px;
}

.formContainer{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-column-gap:20vh; /* Espacio entre las columnas */
}

.form{
    display: flex;
    flex-direction: column;
}

.label{
    font-family: Jura;
    font-size: 22px;
    font-weight: 700;
}

.input {
    max-width: 190px;
    height: 30px;
    background-color: #04050c0a;
    border-radius: .5rem;
    padding: 0 1rem;
    border: 2px solid ;
    font-size: 1rem;
    transition: border-color .3s cubic-bezier(.25,.01,.25,1) 0s, color .3s cubic-bezier(.25,.01,.25,1) 0s,background .2s cubic-bezier(.25,.01,.25,1) 0s;
  }

  .description{
    font-family: Jura;
    font-size: 20px;
    margin: 0 5% 5% 5%;
  }


  button {
    /* Variables */
    margin-top: 10px;
    --button_radius: 0.75em;
    --button_color: #e8e8e8;
    --button_outline_color: #000000;
    font-size: 17px;
    font-weight: bold;
    border: none;
    border-radius: var(--button_radius);
    background: var(--button_outline_color);
  }
  
  .buttonTop {
    display: block;
    box-sizing: border-box;
    border: 2px solid var(--button_outline_color);
    border-radius: var(--button_radius);
    padding: 0.75em 1.5em;
    background: var(--button_color);
    color: var(--button_outline_color);
    transform: translateY(-0.2em);
    transition: transform 0.1s ease;
  }
  
  button:hover .buttonTop {
    /* Pull the button upwards when hovered */
    transform: translateY(-0.33em);
  }
  
  button:active .buttonTop {
    /* Push the button downwards when pressed */
    transform: translateY(0);
  }


  .error{
    color: red;
  }


  .pq{
    font-size: 22px;
    font-weight: bold;
  }

  .clear {
    clear: both;
  }
  
  .agree{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .agreesino{
    font-family: Jura;
    font-weight: bold;
    margin-left: 7px;
  }
  .checkBoxSi {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 3px solid rgba(255, 255, 255, 0);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px #000000;
  }
  
  .checkBoxSi div {
    width: 60px;
    height: 60px;
    background-color: lime;
    top: -52px;
    left: -52px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 100;
  }
  
  .checkBoxSi input[type="checkbox"]:checked + div {
    left: -10px;
    top: -10px;
  }
  
  .checkBoxSi input[type="checkbox"] {
    position: absolute;
    left: 50px;
    visibility: hidden;
  }
  
  .transitionSi {
    transition: 300ms ease;
  }
  

  .checkBoxNo {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 3px solid rgba(255, 255, 255, 0);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 0px 2px #000000;
  }
  
  .checkBoxNo div {
    width: 60px;
    height: 60px;
    background-color: rgb(231, 6, 6);
    top: -52px;
    left: -52px;
    position: absolute;
    transform: rotateZ(45deg);
    z-index: 100;
  }
  
  .checkBoxNo input[type="checkbox"]:checked + div {
    left: -10px;
    top: -10px;
  }
  
  .checkBoxNo input[type="checkbox"] {
    position: absolute;
    left: 50px;
    visibility: hidden;
  }
  
  .transitionNo {
    transition: 300ms ease;
  }

  @media (max-width: 750px) {
    .title{
      font-size: 22px;
    }
    .img{
      width: 300px;
      height: 300px;
  }
  }


  
  @media (max-width: 650px) {
    .img{
      width: 270px;
      height: 270px;
  }
  .label{
    font-size: 19px;
}
.formContainer{
  grid-column-gap:15vh; /* Espacio entre las columnas */
}

  }


  @media (max-width: 650px) {
.formContainer{
  grid-column-gap:10vh; /* Espacio entre las columnas */
}

  }


  @media (max-width: 550px) {
    .img{
      width: 220px;
      height: 220px;
  }
  .label{
    font-size: 17px;
}
.formContainer{
  grid-column-gap:12vh; /* Espacio entre las columnas */
}
.input {
  max-width: 170px;
  height: 25px;
}
.description{
  font-size: 18px;
}
  }


  @media (max-width: 500px) {
    .formContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .img{
      width: 240px;
      height: 240px;
  }
  .label{
    font-size: 20px;
}
.input {
  max-width: 190px;
  height: 28px;
}

.description{
  font-size: 16px;
}

}
    
      