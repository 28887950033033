
.Allbutton {
    color: rgb(124, 52, 34);
    background: #b6b3b3;
    border: none;
    padding: 10px 20px;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    width: 120px;
    text-transform: uppercase;
    cursor: pointer;
    transform: skew(-21deg);
  }
  
  .span {
    display: inline-block;
    transform: skew(21deg);
  }
  
  .Allbutton::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    left: 0;
    background: rgb(20, 20, 20);
    opacity: 0;
    z-index: -1;
    transition: all 0.5s;
  }
  
  .Allbutton:hover {
    color: #fff;
  }
  
 .Allbutton:hover::before {
    left: 0;
    right: 0;
    opacity: 1;
  }

.buttonsContainer{
  margin: 0 5% 5% 5% ;
  display: flex;
  justify-content: space-between;
}



.cerrar {
  align-items: center;
  background-color: transparent;
  color: rgb(189, 19, 19);
  cursor: pointer;
  display: flex;
  font-family: "Jura";
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  text-decoration:underline;
  outline: 0;
  border: 0;
  padding: 1rem;
}

.cerrar:before {
  background-color:black;
  content: "";
  display: inline-block;
  height: 1px;
  margin-right: 10px;
  transition: all .42s cubic-bezier(.25,.8,.25,1);
  width: 0;
}

.cerrar:hover:before {
  background-color: black;
  width: 1.5rem;
}