.card {
    max-width: 300px;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.3);
    border: 1px solid transparent;
  }
  .Cardscontainer{
    display: flex;
    align-items: center;
  }
  
  .content {
    padding: 1.1rem;
  }
  
  .image {
    object-fit: cover;
    width: 100%;
    height: 150px;
    background-color: rgb(239, 205, 255);
  }
  
  .title {
    color: #111827;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 600;
    font-family: "Jura";
  }
  
  .desc {
    margin-top: 0.5rem;
    color: #6B7280;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-family: "Jura";
  }
  

  
.button {
    padding: 12.5px 25px;
    border: 0;
    border-radius: 100px;
    background-color: #b33b2b;
    color: #ffffff;
    font-weight: Bold;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    font-size: 15px;
  }
  .button:hover {
    background-color: #c24f40;
    transform: scale(1.1);
  }
  
  .button:active {
    background-color: #cf3d3d;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    box-shadow: none;
    transform: scale(0.98);
  }
  