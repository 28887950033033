.footerContainer{
    background-color:#333333;
}
.nav{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.nav p {
    color: #FFFFFF;
    font-family: Jura;
    font-weight: 600;
    cursor: pointer;
}

.nav p:hover{
    color: #B32F12;
}

.derechos{
    display: flex;    
    justify-content: center;
}

.derechos p {
    color: #FFFFFF;
    font-family: Jura;
    font-weight: 500;
}


@media (max-width: 500px) {
    .nav{
        flex-direction: column;
        margin-left: 20px;
    }
  }

  @media (max-width: 390px) {
    .derechos{
        margin-left: 20px;
    }
  }


