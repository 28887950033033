.headerContainer{
    background-image: url("../../Assets/Images/Mapa_mundi_blanco.png");
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.navBar{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-decoration: none;
}

.navBar a {
    font-family: Jura;
    color: black ;
    font-weight: 800;
}

.navBar a :hover{
    color:#B32F12
}
.sloganContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    font-family: Jura;
    color:#B32F12;
    font-weight:bolder;
    font-size: 30px;
    margin: 5px 0 0 0;
}

.number{
    font-family: Jura;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
}

@media (max-width: 550px) {
    .title{
      font-size: 25px;
    }
    .number{
        font-size: 16px;
    }
  }
  @media (max-width: 500px) {
    .headerContainer{
        padding-bottom: 30px;
    }
  }
  


  @media (max-width: 440px) {
    .title{
      font-size: 23px;
    }
    .number{
        font-size: 14px;
    }
  }


  @media (max-width: 400px) {
    .title{
      font-size: 20px;
    }
    .number{
        font-size: 14px;
    }
  }

  @media (max-width: 350px) {
    .title{
      font-size: 18px;
    }
    .number{
        font-size: 14px;
    }
  }


