.formContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 100px 500px 100px 500px;    
    border: 2px solid black;
    border-radius: 30px;
    padding: 10px 10px 10px 10px;
    font-family: Jura;
}

.codeContainer{
    display: flex;
    flex-direction: column;
    margin: 5% 40% 5% 40%;    
    border: 2px solid black;
    border-radius: 30px;
    padding: 20px 20px 20px 20px;
    font-family: Jura;
}

.input {
    height: 30px;
    background-color: #04050c0a;
    border-radius: .5rem;
    padding: 0 1rem;
    border: 2px solid ;
    font-size: 1rem;
    transition: border-color .3s cubic-bezier(.25,.01,.25,1) 0s, color .3s cubic-bezier(.25,.01,.25,1) 0s,background .2s cubic-bezier(.25,.01,.25,1) 0s;
  }

  button {
    /* Variables */
    margin-top: 10px;
    margin-bottom: 10px;
    --button_radius: 0.75em;
    --button_color: #e8e8e8;
    --button_outline_color: #000000;
    font-size: 17px;
    font-weight: bold;
    border: none;
    border-radius: var(--button_radius);
    background: var(--button_outline_color);
  }
  
  .buttonTop {
    display: block;
    box-sizing: border-box;
    border: 2px solid var(--button_outline_color);
    border-radius: var(--button_radius);
    padding: 0.75em 1.5em;
    background: var(--button_color);
    color: var(--button_outline_color);
    transform: translateY(-0.2em);
    transition: transform 0.1s ease;
  }
  
  button:hover .buttonTop {
    /* Pull the button upwards when hovered */
    transform: translateY(-0.33em);
  }
  
  button:active .buttonTop {
    /* Push the button downwards when pressed */
    transform: translateY(0);
  }